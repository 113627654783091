import React,{useEffect, useState} from "react";
import Layout from "../components/layout";
import 'animation.gsap'
import 'debug.addIndicators'
import '../resources/styles/pages/_website-development.scss'
import Subgrid from "../components/subgrid/index";
import CodicateImage from "../components/codicate-image/index";
import TransitionButton from "../components/transition-button/index";
import {PLATFORM_ICON_MAPPER, TECH_ICON_MAPPER} from "../utils/constants";
import { graphql} from "gatsby";
import Utils from "../utils/utils";

import 'animation.gsap'
import 'debug.addIndicators'
import ScrollMagic from 'scrollmagic';

// const TweenMax = require('TweenMax');


const ProjectInnerPage = ({data}) => {

    const {wordpressPage} = data;
    const {acf} = wordpressPage;

    const sectionsTiming = [];

    const [menuOnBody, setMenuOnBody] = useState(false);
    const scene = [];

    useEffect(() => {
        //componentDidMount

        const controller = new ScrollMagic.Controller();

            scene.push(new ScrollMagic.Scene({offset: Utils.calcDurationByRegularScreen(0.4)})
                .on("enter", () => setMenuOnBody(true))
                .on("leave", () => setMenuOnBody(false))
                .addTo(controller));

        return () => {
            //componentDidUnMount

            scene.forEach(function (el) {
                el.destroy(true);
                el = null;
            });
        }
    }, [menuOnBody]);

    const imageSlides = acf.projectImages && acf.projectImages.length
        ?
        acf.projectImages.map((image, i)  => ({content: <CodicateImage key={`project-image-${i}`} img={image.image.localFile}/>}))
        : [];

    return (
        <Layout className={`page-project-inner blueBg ${menuOnBody ? 'menuOnBody' : ''}`} isIncludeFooter={false}
                hasGrisSquares={false}
                hasMovingSquares={false}
                seotitle={data.wordpressPage.acf.seoTitle}
                pageTitle={data.wordpressPage.title}
                yoast={data.wordpressPage.yoast || null}
        >
            <Subgrid
                sectionsTiming={sectionsTiming}
                hasArrow={false}
                imagesSlides={imageSlides}
                textSlides={
                    [{
                        content:
                            <div className="text-area-container">
                                <TransitionButton to="/projects" className="close-icon-container"/>
                                <div className="logo-container">
                                    {acf.projectLogo && <CodicateImage img={acf.projectLogo.localFile}/>}
                                </div>
                                {acf.companyIntroText &&
                                    <span className="company-sub-title">{acf.companyIntroText}</span>
                                }
                                {acf.projectDescription  &&
                                    <p className="company-text">{acf.projectDescription}</p>
                                }
                                <div className="technologies-platforms-container">
                                    <div className="technologies">
                                        <span className="small-title">{acf.technologies.technologyHeading || 'Technologies:'}</span>
                                        {acf.technologies.technologyIcons
                                        && acf.technologies.technologyIcons.length && <div className="icons">
                                            {
                                                acf.technologies.technologyIcons.map((icon, i) =>
                                                    <CodicateImage key={`tech-icon-${i}`} img={TECH_ICON_MAPPER[icon]}/>
                                                )
                                            }
                                        </div>
                                        }
                                    </div>
                                    <div className="platforms">
                                        <span className="small-title">{acf.platforms.platformHeading || 'Platforms:'}</span>
                                        {acf.platforms.platformIcons
                                        && acf.platforms.platformIcons.length &&
                                        <div className="icons">
                                            {
                                                acf.platforms.platformIcons.map((icon, i) =>
                                                    <CodicateImage key={`platform-icon-${i}`} img={PLATFORM_ICON_MAPPER[icon]}/>
                                                )
                                            }
                                        </div>
                                        }
                                    </div>
                                </div>
                                {(acf.quote.quoteHeading || acf.quote.quoteText) &&
                                    <div className="quote-container">
                                        <span className="quotation-marks">“</span>
                                        {acf.quote.quoteHeading && <span className="company-sub-title">{acf.quote.quoteHeading}</span>}
                                        {acf.quote.quoteText && <p className="company-text">{acf.quote.quoteText}</p>}
                                    </div>
                                }
                                {
                                    acf.visitLabel && acf.visitLink &&
                                    <a className={`btn`} href={acf.visitLink} target="_blank" rel="noopener noreferrer">{acf.visitLabel}</a>
                                }

                            </div>
                    }]
                }

            />

        </Layout>
    )
};


export default ProjectInnerPage


export const query = graphql`
    query projectInnerQuery($id: String!){
        wordpressPage(id: { eq: $id}) {
            title
            path
            ...YoastData
            acf {
                seoTitle: seo_title
                projectLogo: project_logo {
                    altText: alt_text
                    localFile  {
                        publicURL
                        childImageSharp {
                            fixed(height: 31){
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                companyIntroText: company_intro_text
                projectDescription: project_description
                technologies {
                    technologyHeading: technology_heading
                    technologyIcons: technology_icons
                }
                platforms {
                    platformHeading: platforms_heading
                    platformIcons: platform_icons
                }
                quote {
                    quoteHeading: quote_heading
                    quoteText: quote_text
                    customerName: customer_name
                    customerImg: customer_image {
                        localFile {
                            publicURL
                        }
                    }
                }
                projectImages: project_images {
                    image {
                        altText: alt_text
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1000){
                                    ...GatsbyImageSharpFluid_withWebp_noBase64
                                }
                            }
                        }
                    }
                }
                visitLabel: visit_website_label
                visitLink: visit_website_link
            }
        }         
        
    }
`;